import React from "react";
import { Link } from "react-router-dom";

import Emoji from "./Emoji";

import Macbook from "../../utils/images/Macbook.png";

import "./ProjectPreview.css";

export default class ProjectPreview extends React.Component {
  render() {
    return (
      <div className="project-preview" onClick={() => this.props.onClick()}>
        <div
          className="project-preview-col _first"
          style={{
            ...this.props.project.style,
          }}
        >
          <div className="project-preview-heading">
            <div className="project-preview-name">
              {this.props.project.name}
            </div>
            <div className="project-preview-emoji">
              <Emoji emoji={this.props.project.emoji} />
            </div>
          </div>
          <div className="project-preview-type">{this.props.project.type}</div>
          <div
            className="project-preview-description"
            style={{ color: this.props.project.style.accent }}
          >
            {this.props.project.description}
          </div>
          <Link
            style={{ color: this.props.project.style.color }}
            to={this.props.project.url}
            className="project-preview-link"
            onClick={(e) => e.stopPropagation()}
          >
            {this.props.project.url}
          </Link>
        </div>
        <div className="project-preview-col _second">
          <div className="project-preview-image-wrapper">
            <img
              className="project-preview-image"
              src={Macbook}
              alt="preview"
            />
            <img
              className="project-preview-image2"
              src={this.props.project.rows[0].image}
              alt=""
            />
          </div>
        </div>
      </div>
    );
  }
}
