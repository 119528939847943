import Gabe from "../../utils/images/gabe.jpg";
import Robert from "../../utils/images/robert.jpg";

const Team = [
  {
    name: "Gabe Earle",
    role: "Frontend Design & Development",
    image: Gabe,
  },
  {
    name: "Robert Thomas",
    role: "Backend & Cloud Development",
    image: Robert,
  },
];

export default Team;
