import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import "./Menu.css";

export default function Menu(props) {
  const [menu, setMenu] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setMenu(false);
  }, [location]);

  return (
    <div id="menu">
      {menu && <MenuContainer nav={props.nav} />}

      <div
        id="menu-toggle"
        className={menu ? "_open" : ""}
        onClick={() => setMenu((m) => !m)}
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

export class MenuContainer extends React.Component {
  state = {
    page: window.location.pathname,
  };

  componentDidMount() {
    setTimeout(() => this.container.classList.add("_open"), 6);
  }
  render() {
    return (
      <div
        id="menu-container"
        ref={(container) => (this.container = container)}
      >
        <div id="menu-content">
          {this.props.nav.map((link, index) => (
            <NavLink className="menu-nav underline" to={link.link} key={index}>
              {link.name}
            </NavLink>
          ))}
        </div>
      </div>
    );
  }
}
