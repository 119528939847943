import React from "react";
import autosize from "autosize";

import "./Inputs.css";

export default class TextArea extends React.Component {
  state = {
    focus: false,
  };

  componentDidMount() {
    autosize(this.textarea);
  }

  render() {
    return (
      <div
        className="input"
        onFocus={() => this.setState({ focus: true })}
        onBlur={() => this.setState({ focus: false })}
      >
        <div
          className={`input-name ${
            (this.state.focus || this.props.value) && "focus"
          }`}
          onClick={() => {
            this.setState({ focus: true });
            this.textarea.focus();
          }}
        >
          {this.props.name}
        </div>
        <textarea
          ref={(c) => (this.textarea = c)}
          rows="1"
          wrap="soft"
          spellCheck="false"
          className="textarea-field"
          value={this.props.value}
          onChange={(e) => this.props.setValue(e.target.value)}
        />
      </div>
    );
  }
}
