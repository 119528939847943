import es1 from "../../utils/images/es1.png";
import es2 from "../../utils/images/es2.png";
import es3 from "../../utils/images/es3.png";
import es4 from "../../utils/images/es4.png";
import ss1 from "../../utils/images/ss1.png";
import ss2 from "../../utils/images/ss2.png";
import ss3 from "../../utils/images/ss3.png";

const Projects = [
  {
    name: "Electronic Samples",
    type: "E-commerce",
    description:
      "Electronic Samples is a web store for makers of electronic music.  Independent artists use the site to find professional tools for producing release-ready tracks.",
    link: "electronicsamples",
    url: "https://electronicsamples.com",
    rows: [
      {
        title: "Design",
        text: [
          <span key={1}>
            Electronic Samples is a webstore developed for Youtube music content
            creator{" "}
          </span>,
          <a
            rel="noreferrer noopener"
            target={"_blank"}
            style={{ textDecoration: "underline" }}
            href="https://www.youtube.com/julienearle"
            key={2}
          >
            Julien Earle
          </a>,
          <span key={3}>
            . Julien's brand aesthetic is all about timelessness, black and
            white, and minimalism. Design inspiration was drawn from avant-garde
            fashion houses and artists. The end product is a striking web store
            with a spacious layout that invokes luxury and quality.
          </span>,
        ],
        image: es2,
        type: "macbook",
      },
      {
        title: "Control Center",
        text: "A desktop app houses all of the controls, stats, and management features for electronicsamples.com.  At a glance, Julien can view daily revenue trends and product performance using a 100% custom built data visualization suite.  Intelligent features like tag-based performance reports help Julien understand customer demand so he can better serve his user community.",
        image: es4,
        type: "macbook",
      },
      {
        title: "Storefront",
        text: "Electronic Samples offered over 600 unique sample packs from launch day.  An intuitive system for navigating, filtering, and sorting the products list was essential.  The product data was algorithmically labelled using Python to power the filtering process.  As a result, users can find content by genre, product type, and more in just a couple clicks.",
        image: es1,
        type: "macbook",
      },
      {
        title: "Checkout",
        text: "Checking out on electronicsamples.com couldn't be easier.  After supplying their name and email, a user interacts with an encrypted web form to provide payment.  Next, they find a download page where they can immediately access their files.  The user is also sent a personalized email with their download link for convenience.  Download links are tokenized for 24 hours of access to minimize unlicensed file sharing.",
        image: es3,
        type: "macbook",
      },
    ],
    emoji: "🎧",
    style: {
      fontFamily: "Bebas Neue",
      letterSpacing: ".5px",
      background: "black",
      lineHeight: "1.5rem",
      color: "white",
      accent: "#ccc",
    },
    modalStyle: {
      background: "white",
      color: "black",
      accent: "black",
    },
  },
  {
    name: "Song to Stems",
    type: "Machine Learning Model",
    description:
      "Song to Stems is a subscription-based AI as a Service product built for the music community.  Users upload songs and Song to Stems' AI model breaks the audio down into individual instruments for studying, remixing, or just singing along.",
    link: "songtostems",
    url: "https://songtostems.com",
    rows: [
      {
        title: "Service",
        text: "Access to the service is found on the home page.  Users are immediately greeted with an upload prompt where they can drag and drop or manually upload as many audio files as they wish.  The audio is sent to the machine learning model, hosted on Amazon Web Services, and the model's outputs are returned to the user upon completion.",
        image: ss1,
        type: "macbook",
      },
      {
        title: "Design",
        text: "The design goal for Song to Stems was to keep the mood simple and light.  A straightforward layout with fun colors was used to create a product that promotes creativity.  Users access Song to Stems when inspiration strikes, so time spent sorting through UIs was kept to an absolute minimum.  A conversion can be started in seconds by dragging files directly from an audio workstation or browser downloads bar.",
        image: ss2,
        type: "macbook",
      },
      {
        title: "Accounts",
        text: "Account management is an essential part of Song to Stems, for authenticating use of the machine learning model and managing subscriptions.  Users have access to all of their info in summary from their account page. Billing tasks such as switching subscription levels and changing payment sources can be completed in just a few clicks.",
        image: ss3,
        type: "macbook",
      },
    ],
    emoji: "🎤",
    style: {
      fontFamily: "Poppins",
      background: "#fa7324",
      color: "white",
      accent: "#fcd788",
    },
    modalStyle: {
      color: "white",
      accent: "#fcd788",
    },
  },
];

export default Projects;
