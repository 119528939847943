import webdev from "../../utils/images/webdev.png";
import es2 from "../../utils/images/es2.png";
import code from "../../utils/images/code.png";
import ss1 from "../../utils/images/ss1.png";

const Services = [
  {
    name: "Web Development",
    emoji: "💻",
    text: [
      "We ❤ users. That's why we get so excited about building websites that combine beauty and functionality. We work on projects ranging from portfolios and information pages to full-scale web apps and experiences.",
    ],
    contact: "Let's talk.",
    id: "webdev",
    link: "/contact?subject=Web%20Development%20Project",
    image: webdev,
  },
  {
    name: "Web Stores",
    emoji: "🎁",
    text: "You've got something the world needs. All that's left is a place to sell it. We build 100% custom web stores that maximize customer experience and minimize your fees. It's time for your web store to embody your brand identity and cut out middlemen.",
    contact: "Get started.",
    id: "webstores",
    link: "/contact?subject=Web%20Store%20Project",
    image: es2,
  },
  {
    name: "Cloud and API",
    emoji: "⛅",
    text: "We've got our heads in the cloud. No really, we build full stack solutions for serving your content and software to anyone, anywhere. Let us handle things like billing, accounts, subscriptions, scaling, and SAAS delivery. You focus on the product.",
    contact: "Tell us more.",
    id: "cloud",
    link: "/contact?subject=Cloud%20Service%20Project",
    image: code,
  },
  {
    name: "AI as a Service",
    emoji: "🤖",
    text: "Your newly trained model could change the world. We can get your technology in the hands of customers with custom web stacks for training and usage.  We might as well have some fun before they take over.",
    contact: "Reach out.",
    id: "ai",
    link: "/contact?subject=AI%20Project",
    image: ss1,
  },
];

export default Services;
