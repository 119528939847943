import React from "react";

const Emoji = (props) => (
  <span
    className="emoji"
    role="img"
    aria-label={props.label ? props.label : ""}
    aria-hidden={props.label ? "false" : "true"}
  >
    <span className="emoji-emoji">{props.emoji}</span>
    {props.text && <span className={"emoji-text"}> {props.text}</span>}
  </span>
);

export default Emoji;
