import React from "react";

import "./Inputs.css";

export default class Input extends React.Component {
  state = {
    focus: false,
  };

  render() {
    return (
      <div
        className="input"
        onFocus={() => this.setState({ focus: true })}
        onBlur={() => this.setState({ focus: false })}
      >
        <div
          className={`input-name ${
            (this.state.focus || this.props.value) && "focus"
          }`}
          onClick={() => {
            this.setState({ focus: true });
            this.input.focus();
          }}
        >
          {this.props.name}
        </div>
        <input
          ref={(input) => (this.input = input)}
          type={this.props.password && "password"}
          className="input-field"
          value={this.props.value}
          onChange={(e) => this.props.setValue(e.target.value)}
        ></input>
      </div>
    );
  }
}
