import React from "react";

import "./Carousel.css";

export default class Carousel extends React.Component {
  state = {
    slide: 0,
  };

  render() {
    return (
      <div className="carousel-wrapper" id={this.props.id}>
        <div className="carousel-container">
          <div
            className="carousel-button _left"
            onClick={() => this.setState({ slide: this.state.slide - 1 })}
          ></div>
          <div
            className="carousel-button _right"
            onClick={() => this.setState({ slide: this.state.slide + 1 })}
          ></div>
          <div
            className="carousel"
            style={{
              transform: `translateX(calc(-${
                ((this.state.slide % this.props.children.length) +
                  this.props.children.length) %
                this.props.children.length
              } * (8rem + 100%)))`,
            }}
          >
            {this.props.children.map((child, index) => (
              <div className="carousel-slide" key={index}>
                {child}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
