import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import { Header, Footer } from "./organisms";
import { Overlay } from "./organisms/misc";

import { HomePage, ProjectsPage, TeamPage, ContactPage } from "./pages";

import "./App.css";

export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Header />
      <div id="container">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/projects/*" element={<ProjectsPage />} />
          <Route path="/team/*" element={<TeamPage />} />
          <Route path="/contact/*" element={<ContactPage />} />
          <Route path="/*" element={<NoMatch />} />
        </Routes>
      </div>
      <Footer />
      <Overlay />
    </>
  );
}

function NoMatch() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/", { replace: true });
  });
  return null;
}
