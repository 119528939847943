import axios from "axios";

class API {
  constructor() {
    if (process.env.NODE_ENV === "development") {
      this.url = "https://api.pebble.dev";
    } else {
      this.url = "https://api.pebble.dev";
    }
  }

  //support
  contact(name, email, subject, message, cb) {
    const body = {
      name: name,
      email: email,
      subject: subject,
      message: message,
    };
    axios
      .post(this.url + "/contact", body)
      .then((response) => {
        cb(null, response);
      })
      .catch((err) => {
        console.log(err.response?.data?.msg);
        cb(err);
      });
  }
}

export default new API();
