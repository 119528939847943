import React from "react";
import { useNavigate } from "react-router-dom";

import { Banner, Projects, Expertise, Services } from "./components";
import { CTA, Emoji } from "../../organisms/misc";

import "./HomePage.css";

export default function HomePage(props) {
  const navigate = useNavigate();

  return (
    <div id="home-page" className="page">
      <Banner />
      <Services number={"01"} />
      <Expertise number={"02"} />
      <Projects number={"03"} />
      <CTA
        number={"04"}
        title={"Get In Touch"}
        text={
          <>
            <div className="home-cta-text _first">Dream up a website.</div>
            <div className="home-cta-text">We've got the rest.</div>
            <div className="button" onClick={() => navigate("/contact")}>
              <Emoji emoji="💬" /> Let's Talk
            </div>
          </>
        }
      />
    </div>
  );
}
