import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Emoji from "../../../organisms/misc/Emoji";
import ProjectPreview from "../../../organisms/misc/ProjectPreview";
import ProjectsData from "../../../organisms/misc/ProjectsData";

export default function Projects(props) {
  const navigate = useNavigate();

  return (
    <div id="home-projects" className="home-section">
      <div
        id="home-projects-background"
        className="background home-section-background"
      />
      <div
        id="home-projects-background2"
        className="background home-section-background"
      />
      <div className="content">
        <div className="home-section-header">
          <div className="home-section-number">{props.number}</div>
          <div className="home-section-title">Projects</div>
          <div
            className="home-section-link underline"
            onClick={() => navigate("/projects")}
          >
            See more
          </div>
        </div>
        {ProjectsData.slice(1, 2).map((project, index) => (
          <ProjectPreview
            project={project}
            key={index}
            onClick={() => navigate(`/projects/${project.link}`)}
          />
        ))}
        <div
          id="home-projects-button"
          className="button"
          onClick={() => navigate("/projects")}
        >
          <Emoji emoji="🔍" /> More Projects
        </div>
      </div>
    </div>
  );
}
