import React from "react";

import Carousel from "../../../organisms/misc/Carousel";
import Emoji from "../../../organisms/misc/Emoji";

import ExpertiseData from "../../../organisms/misc/ExpertiseData";

export default class Expertise extends React.Component {
  render() {
    return (
      <div id="home-expertise" className="home-section">
        <div
          id="home-expertise-background"
          className="background home-section-background"
        />
        <div
          id="home-expertise-background2"
          className="background home-section-background"
        />
        <div className="home-section-header content">
          <div className="home-section-number">{this.props.number}</div>
          <div className="home-section-title">Expertise</div>
        </div>
        <Carousel id={"home-projects-carousel"}>
          {ExpertiseData.map((expertise, index) => (
            <div className="home-expertise-panel panel" key={index}>
              <div className="home-expertise-title">{expertise.title}</div>
              <div className={`home-expertise-topics ${expertise.class}`}>
                {expertise.topics.map((topic, index) => (
                  <div className="home-expertise-topic" key={index}>
                    <div className="home-expertise-topic-title">
                      {topic.icon && (
                        <img
                          src={topic.icon}
                          alt=""
                          className="home-expertise-topic-icon"
                        />
                      )}
                      {topic.emoji && <Emoji emoji={topic.emoji} />}{" "}
                      {topic.title}
                    </div>
                    <div className="home-expertise-topic-text">
                      {topic.text}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    );
  }
}
