import React from "react";
import { useNavigate } from "react-router-dom";

import { CTA, Emoji } from "../../organisms/misc";
import team from "../../organisms/misc/TeamData";

import "./TeamPage.css";

export default function TeamPage(props) {
  const navigate = useNavigate();

  return (
    <div className="page">
      <div id="projects-banner" className="banner">
        <div id="projects-banner-background" className="background" />
        <div id="projects-banner-background2" className="background" />
        <div id="projects-banner" className="panel">
          <div id="projects-banner-title">
            We're two software developers from Washington, D.C.
          </div>
          <div id="projects-banner-text">Here's more about us.</div>
        </div>
      </div>
      <div className="content">
        <div className="home-section-header">
          <div className="home-section-number">01</div>
          <div className="home-section-title">Team</div>
        </div>
        <div className="team-members">
          {team.map((member, i) => (
            <div key={i} className="team-member">
              <div className={`team-member-image _${member.name}`}>
                <img src={member.image} />
              </div>
              <div className="team-member-name">{member.name}</div>
              <div className="team-member-role">{member.role}</div>
            </div>
          ))}
        </div>
      </div>
      <CTA
        number={"02"}
        title={"Get In Touch"}
        text={
          <>
            <div className="home-cta-text _first">Dream up a website.</div>
            <div className="home-cta-text">We've got the rest.</div>
            <div className="button" onClick={() => navigate("/contact")}>
              <Emoji emoji="💬" /> Let's Talk
            </div>
          </>
        }
      />
    </div>
  );
}
