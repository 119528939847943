import html from "../../utils/images/html.png";
import css from "../../utils/images/css.png";
import js from "../../utils/images/js.png";
import react from "../../utils/images/react.png";
import angular from "../../utils/images/angular.png";
import aws from "../../utils/images/aws.png";
import mongo from "../../utils/images/mongo.png";

const Expertise = [
  {
    title: "UI/UX",
    topics: [
      {
        emoji: "💡",
        title: "Intuitive",
        text: "We take user experience personally.  Great web interactions just make sense, no tutorial required.",
      },
      {
        emoji: "📱",
        title: "Responsive",
        text: "Users come in all shapes and sizes.  Ensuring your new website behaves across platforms is at the core of our mission.",
      },
      {
        emoji: "✨",
        title: "Beautiful",
        text: "Whether your site needs a ground-up or a fresh coat, we'll craft a look that turns heads and embodies your brand's identity.",
      },
    ],
  },
  {
    title: "Digital Payments",
    topics: [
      {
        emoji: "📉",
        title: "Lowest Fees",
        text: "We integrate payments directly, eliminating the most middlemen possible.  Your customers would want it that way.",
      },
      {
        emoji: "🔒",
        title: "Secure",
        text: "We encrypt and sandbox all payment data.  Your customers place their trust in you, and we don't take that lightly.",
      },
      {
        emoji: "🔩",
        title: "Automated",
        text: "Payments will be built into your web stack, organizing things like products, sales, and subscriptions all in your custom built dashboard.",
      },
    ],
  },
  {
    title: "Cloud",
    topics: [
      {
        emoji: "⛅",
        title: "Hosting",
        text: "Once your new website's ready, it's time to host.  We'll make sure users everywhere can connect quickly and securely.",
      },
      {
        emoji: "📫",
        title: "Email",
        text: "From app notifications to marketing, we design and send email in-house, so you'll be paying the lowest costs possible.",
      },
      {
        emoji: "📡",
        title: "API",
        text: "We've built secure REST APIs for projects ranging from web stores to communication networks for autonomous robotics.",
      },
    ],
  },
  {
    title: "Server",
    topics: [
      {
        emoji: "🔧",
        title: "Back End",
        text: "A great web app is nothing without a brain.  We develop traditional and serverless systems to suit all kinds of projects.",
      },
      {
        emoji: "💾",
        title: "Database",
        text: "Whether your project requires web assets, user uploads, or an account database, we've got the experience to design a robust database solution.",
      },
      {
        emoji: "📈",
        title: "Scale",
        text: "The project's done and users are lining up.  Our systems auto-scale, handling peaks and saving costs in lulls.",
      },
    ],
  },
  {
    title: "Featured Tech",
    class: "_webtech",
    topics: [
      {
        icon: html,
        title: "HTML",
      },
      {
        icon: css,
        title: "CSS",
      },
      {
        icon: js,
        title: "JavaScript",
      },
      {
        icon: react,
        title: "React",
      },
      {
        icon: angular,
        title: "Angular",
      },
      {
        icon: aws,
        title: "AWS",
      },
      {
        icon: mongo,
        title: "MongoDB",
      },
    ],
  },
];

export default Expertise;
