import React from "react";
import { useNavigate } from "react-router-dom";

import ServicesData from "../../../organisms/misc/ServicesData";
import Emoji from "../../../organisms/misc/Emoji";

export default class Services extends React.Component {
  state = {};

  render() {
    return (
      <div id="home-services" className="home-section">
        <div
          id="home-services-background"
          className="background home-section-background"
        />
        <div className="content">
          <div className="home-section-header">
            <div className="home-section-number">{this.props.number}</div>
            <div className="home-section-title">Services</div>
          </div>
          {ServicesData.map((service, index) => (
            <Service {...service} key={index} />
          ))}
        </div>
      </div>
    );
  }
}

function Service(props) {
  const navigate = useNavigate();

  return (
    <div className="home-service">
      <div
        id={props.id}
        style={{ top: "-18rem", position: "absolute", visibility: "hidden" }}
      />
      <div className="home-service-col">
        <div className="home-service-title">
          <Emoji emoji={props.emoji} /> {props.name}
        </div>
        <div className="home-service-text">{props.text}</div>
        <div
          onClick={() => navigate(props.link)}
          className="home-service-contact underline"
        >
          {props.contact}
        </div>
      </div>
      <div className="home-service-col _image">
        <img className="home-service-image" src={props.image} alt="" />
      </div>
    </div>
  );
}
