import React, { useState, useEffect } from "react";
import { Link, useParams, useMatch } from "react-router-dom";

import projects from "../../../organisms/misc/ProjectsData";
import Carousel from "../../../organisms/misc/Carousel";
import Emoji from "../../../organisms/misc/Emoji";
import Macbook from "../../../utils/images/Macbook.png";

import "./ProjectModal.css";

export default function ProjectModal(props) {
  const [project, setProject] = useState(null);

  const params = useParams();

  useEffect(() => {
    setProject(projects.find((p) => p.link === params.project));
  });

  return (
    <div id="project-modal" onClick={() => props.close()}>
      <div id="project-modal-wrapper">
        <div
          id="project-modal-container"
          style={{
            ...project?.style,
            ...project?.modalStyle,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            onClick={() => props.close()}
            id="project-modal-close"
            className="underline"
          >
            close
          </div>
          <div id="project-modal-grid">
            <div id="project-modal-title">
              {project?.name} <Emoji emoji={project?.emoji} />
            </div>
            <div id="project-modal-subtitle">
              <div
                id="project-modal-type"
                style={{ color: project?.modalStyle.accent }}
              >
                {project?.type}
              </div>
              <a
                style={{ color: project?.modalStyle.color }}
                id="project-modal-link"
                href={project?.url}
                target="_blank"
                rel="noreferrer noopener"
              >
                {project?.url}
              </a>
            </div>
            {project?.rows.length && (
              <Carousel>
                {project.rows.map((row, index) => (
                  <ProjectRow
                    {...row}
                    accent={project?.modalStyle.accent}
                    key={index}
                  />
                ))}
              </Carousel>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

class ProjectRow extends React.Component {
  render() {
    return (
      <div className="project-modal-row">
        <div className="project-modal-image-wrapper">
          {this.props.type === "macbook" && (
            <img className="project-modal-image" src={Macbook} alt="preview" />
          )}
          <img className="project-modal-image2" src={this.props.image} alt="" />
        </div>
        <div className="project-modal-column">
          <div className="project-modal-column-title">{this.props.title}</div>
          <div
            className="project-modal-column-text"
            style={{ color: this.props.accent }}
          >
            {this.props.text}
          </div>
        </div>
      </div>
    );
  }
}
