import React from "react";

export default function CTA(props) {
  return (
    <div id="home-cta" className="home-section">
      <div
        id="home-cta-background"
        className="background home-section-background"
      />
      <div id="home-cta-contant" className="content">
        <div className="home-section-header">
          {props.number && (
            <div className="home-section-number">{props.number}</div>
          )}
          {props.title && (
            <div className="home-section-title">{props.title}</div>
          )}
        </div>
        <div
          id="home-cta-background2"
          className="background home-section-background"
        />
        <div
          id="home-cta-background3"
          className="background home-section-background"
        />
        {props.text && (
          <div id="home-cta-panel" className="panel">
            {props.text}
          </div>
        )}
      </div>
    </div>
  );
}
