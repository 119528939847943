import React from "react";
import { Link } from "react-router-dom";

import "./Footer.css";

export default class Footer extends React.Component {
  state = {
    footer: {
      menu: [
        { name: "Home", link: "/" },
        { name: "Projects", link: "/projects" },
        { name: "Contact", link: "/contact" },
      ],
      services: [
        { name: "Web Development", link: "/#webdev" },
        { name: "Web Stores", link: "/#webstores" },
        { name: "Cloud and API", link: "/#cloud" },
        { name: "AI as a Service", link: "/#ai" },
      ],
      projects: [
        { name: "songtostems.com", link: "/projects/songtostems" },
        { name: "electronicsamples.com", link: "/projects/electronicsamples" },
      ],
    },
  };

  render() {
    return (
      <div id="footer">
        <div id="footer-container">
          <div className="footer-col-wrapper">
            <div className="footer-col">
              <div className="footer-col-title">Menu</div>
              {this.state.footer.menu.map((link, index) => (
                <Link
                  className="footer-col-link _menu"
                  to={link.link}
                  key={index}
                >
                  {link.name}
                </Link>
              ))}
            </div>
            <div className="footer-col">
              <div className="footer-col-title">Services</div>
              {this.state.footer.services.map((link, index) => (
                <Link
                  className="footer-col-link _services"
                  to={link.link}
                  key={index}
                >
                  {link.name}
                </Link>
              ))}
            </div>
            <div className="footer-col">
              <div className="footer-col-title">Projects</div>
              {this.state.footer.projects.map((link, index) => (
                <Link
                  className="footer-col-link _projects"
                  to={link.link}
                  key={index}
                >
                  {link.name}
                </Link>
              ))}
            </div>
          </div>
          <Link id="footer-logo" to="/">
            Pebble
          </Link>
        </div>
      </div>
    );
  }
}
