import React from "react";

import API from "../../API/API";

import Emoji from "../../organisms/misc/Emoji";
import { testEmail, parseURL } from "../../API/Helpers";
import { Input, TextArea } from "../../organisms/Inputs";
import logo from "../../utils/images/pebble-p-3d.png";

import "./ContactPage.css";

export default class ContactPage extends React.Component {
  state = {
    name: "",
    email: "",
    subject: "",
    message: "",
    ...parseURL(window.location.search),
    submitError: null,
    submitMessage: null,
  };

  componentDidMount() {
    this.form.addEventListener("keypress", (e) => {
      if (e.key === "Enter" && document.activeElement.tagName !== "TEXTAREA") {
        this.formButton.click();
      }
    });
  }

  contact() {
    this.setState({ submitError: null, submitMessage: null });

    if (!this.state.name) {
      document.activeElement.blur();
      this.setState({ submitError: "Please enter your name." });
      return;
    }
    var emailTest = testEmail(this.state.email);
    if (!this.state.email || !emailTest) {
      document.activeElement.blur();
      this.setState({ submitError: "Please enter a valid email address." });
      return;
    }
    if (!this.state.subject) {
      document.activeElement.blur();
      this.setState({ submitError: "Please enter a subject." });
      return;
    }
    if (!this.state.message) {
      document.activeElement.blur();
      this.setState({ submitError: "Please enter a message." });
      return;
    }

    API.contact(
      this.state.name,
      this.state.email,
      this.state.subject,
      this.state.message,
      (err, response) => {
        if (err) {
          this.setState({
            submitError:
              err.respons?.data?.msg ||
              "Something went wrong while sending your message.  Please try again.",
          });
        } else {
          this.setState({
            name: "",
            email: "",
            subject: "",
            message: "",
            submitMessage: "Your message has been sent.  We'll be in touch!",
          });
        }
      }
    );
  }

  render() {
    return (
      <div id="contact-page" className="page">
        <div id="contact-background" className="background" />

        <div id="contact-grid" className="content">
          <div
            id="contact-form"
            className="contact-content-col"
            ref={(form) => (this.form = form)}
          >
            <div id="contact-title">Upcoming project?</div>
            <div id="contact-subtitle">We'd love to work with you.</div>
            <Input
              name="Name"
              value={this.state.name}
              setValue={(name) => this.setState({ name })}
            />
            <Input
              name="Email"
              value={this.state.email}
              setValue={(email) => this.setState({ email })}
            />
            <Input
              name="Subject"
              value={this.state.subject}
              setValue={(subject) => this.setState({ subject })}
            />
            <TextArea
              name="Message"
              value={this.state.message}
              setValue={(message) => this.setState({ message })}
            />
            {this.state.submitError && (
              <div className="submit-error">{this.state.submitError}</div>
            )}
            {this.state.submitMessage && (
              <div className="submit-error">{this.state.submitMessage}</div>
            )}
            <div
              tabIndex="0"
              className="button"
              id="contact-button"
              onClick={() => this.contact()}
              ref={(formButton) => (this.formButton = formButton)}
            >
              <Emoji emoji="✉️" /> Send
            </div>
          </div>
          <div className="contact-content-col">
            <div id="contact-pebble" src={logo} alt="" />
          </div>
        </div>
      </div>
    );
  }
}
