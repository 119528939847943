import React from "react";
import TextLoop from "react-text-loop";

import Emoji from "../../../organisms/misc/Emoji";

export default class Banner extends React.Component {
  state = {
    clientTypes: [
      { emoji: "💭", text: "dreamers." },
      { emoji: "🔨", text: "doers." },
      { emoji: "🤔", text: "thinkers." },
      { emoji: "💁‍♀️", text: "influencers." },
      { emoji: "📸", text: "creatives." },
      { emoji: "🎨", text: "artists." },
      { emoji: "📏", text: "makers." },
      { emoji: "💸", text: "entrepreneurs." },
      { emoji: "🏠", text: "small businesses." },
      { emoji: "🏢", text: "medium businesses." },
      { emoji: "🏭", text: "large businesses." },
      { emoji: "👪", text: "people." },
      { emoji: "🤖", text: "droids." },
      { emoji: "🔌", text: "AI." },
    ],
  };

  render() {
    return (
      <div id="home-banner" className="banner">
        <div id="home-banner-background" className="background" />
        <div id="home-banner-panel" className="panel">
          <div id="home-banner-title">Hi, we're Pebble.</div>
          <div id="home-banner-text">
            We build web tech for{" "}
            <TextLoop className="home-banner-loop" interval={2000}>
              {this.state.clientTypes.map((client, index) => (
                <Emoji key={index} emoji={client.emoji} text={client.text} />
              ))}
            </TextLoop>
          </div>
        </div>
      </div>
    );
  }
}
