import React, { useState, useEffect } from "react";
import { useNavigate, Routes, Route } from "react-router-dom";

import { ProjectModal } from "./components";
import { CTA, Emoji } from "../../organisms/misc";
import projects from "../../organisms/misc/ProjectsData";
import ProjectPreview from "../../organisms/misc/ProjectPreview";

import "./ProjectsPage.css";

export default function ProjectsPage(props) {
  const navigate = useNavigate();

  return (
    <div id="projects-page" className="page">
      <Routes>
        <Route
          path=":project"
          element={<ProjectModal close={() => navigate("/projects")} />}
        />
      </Routes>

      <div id="projects-banner" className="banner">
        <div id="projects-banner-background" className="background" />
        <div id="projects-banner-background2" className="background" />
        <div id="projects-banner" className="panel">
          <div id="projects-banner-title">
            We get to work with some pretty cool people.
          </div>
          <div id="projects-banner-text">
            Here's some of our favorite projects.
          </div>
        </div>
      </div>
      <div id="projects-content" className="content">
        <div className="home-section-header">
          <div className="home-section-number">01</div>
          <div className="home-section-title">Projects</div>
        </div>
        {projects.map((project, index) => (
          <ProjectPreview
            key={index}
            number={index + 1}
            project={project}
            onClick={() => navigate(`/projects/${project.link}`)}
          />
        ))}
      </div>
      <CTA
        number="02"
        title="People"
        text={
          <>
            <div className="home-cta-text _first">Meet our people.</div>
            <div className="button" onClick={() => navigate("/team")}>
              <Emoji emoji="🤠" /> Team
            </div>
          </>
        }
      />
    </div>
  );
}
