import React from "react";
import { Link, NavLink } from "react-router-dom";

import Menu from "./components/Menu";

import "./Header.css";

export default class Header extends React.Component {
  state = {
    nav: [
      { name: "Home", link: "/" },
      { name: "Projects", link: "/projects" },
      { name: "Team", link: "/team" },
      { name: "Contact", link: "/contact" },
    ],
  };

  render() {
    return (
      <div id="header">
        <div id="header-container">
          <div id="header-content">
            <Link id="header-logo-wrapper" to="/">
              <div id="header-logo" />
              <div id="header-logo-text">Pebble Dev Studio</div>
            </Link>
            <div id="header-nav-wrapper">
              {this.state.nav.map((link, index) => (
                <NavLink className="header-nav" to={link.link} key={index}>
                  {link.name}
                </NavLink>
              ))}
            </div>

            <Menu nav={this.state.nav} />
          </div>
        </div>
      </div>
    );
  }
}
