import React from "react";

import "./Overlay.css";

export default function Overlay() {
  return (
    <div id="overlay">
      <div id="socials">
        <a
          href="https://www.linkedin.com/company/pebbledevstudio"
          target="_blank"
          rel="noreferrer noopener"
        >
          <div className="socials-icon" id="socials-li" />
        </a>
        <a
          href="https://facebook.com/pebbledevstudio"
          target="_blank"
          rel="noreferrer noopener"
        >
          <div className="socials-icon" id="socials-fb" />
        </a>
        <a
          href="https://twitter.com/pebbledevstudio"
          target="_blank"
          rel="noreferrer noopener"
        >
          <div className="socials-icon" id="socials-tw" />
        </a>
        <a
          href="https://instagram.com/pebbledevstudio"
          target="_blank"
          rel="noreferrer noopener"
        >
          <div className="socials-icon" id="socials-ig" />
        </a>
      </div>
      <div id="copyright">©{new Date().getFullYear()} Pebble LLC</div>
    </div>
  );
}
